import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
import CareerComponent from '../../../components/career/CareerComponent';
import AutomationComponent from '../../../components/automation/AutomationComponent';

function AutomationTemplate({ cmsData }) {
    
    const data = cmsData?.automation?.edges[0]?.node?.frontmatter ?? {}

    return (
        <div>
            <AutomationComponent cmsData={data} />
        </div>
    )
}

export default function Automation() {
    return (
      <StaticQuery
        query={graphql`
          query {
            automation: allMarkdownRemark(filter: 
              {frontmatter: {templateKey: {eq: "sv-automation-overview"}}}) {
              edges {
                node {
                  frontmatter {
                    title
                    seo {
                      title
                      descr
                    }
                    bg {
                        childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    summaryList {
                      text
                    }
                    pink {
                      title
                      text
                      buttonText
                      buttonPath
                    }
                    endSection {
                      title
                      icon {
                          childImageSharp {
                          fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                      buttonText
                      buttonPath
                    }
                  }
                }
              }
            },
          }
        `}
        render={(data) => <AutomationTemplate cmsData={data} />}
      />
    );
  }
